.products-main-section .banner{
    height: 100vh;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.products-main-section .home-banner{
    background-image: url('../../assets/products-background.png');
}

.products-main-section .grace-navbar {
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
}

.products-main-section .sticky {
    position: fixed;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 2);
    margin: 0;
}

.products-main-section .grace-navbar img {
    width: 140px;
}

.products-main-section .grace-navbar ul li a{
    color: #ffffff;
    text-decoration: none;
    margin-right: 20px;
    letter-spacing: 1px;
}

.products-main-section .grace-navbar ul li a:hover{
    font-weight: bold;
}


.text-main-wrapper{
    margin-top: 200px;
    color: #ffffff;
    padding-left: 100px;
}

.text-main-wrapper h1{
    font-size: 46px;
}

.text-main-wrapper p{
    margin: 40px 0;
    font-size: 14px;
}

.text-main-wrapper .get-started-button{
    background-color: #B12634;
    color: #ffffff;
    border: none;
    padding: 10px 30px;
    border-radius: 5px;
    font-size: 15px;
}

.small{
    display: none;
}

@media only screen and (max-width: 900px) {
    .small {
        display: block;
    }

    .medium {
        display: none;
    }

    .products-main-section .grace-navbar-small {
        padding: 20px 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
    }
    
    .products-main-section .grace-navbar-small img {
        width: 140px;
    }

    .text-main-wrapper{
        padding: 0;
        margin-top: 150px;
    }

    .grace-drawer{
        padding: 20px;
    }

    .grace-drawer img {
        width: 140px;
    }

    .grace-drawer .links{
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
    }

    .grace-drawer .links a{
        text-decoration: none;
        color: #111;
        padding-left: 10px;
        font-size: 18px;
        letter-spacing: 1px;
    }

    .grace-drawer .links a:hover{
        text-decoration: none;
        color: #111;
    }
}

.language-dropwdown-button-white{
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: #fff;
    letter-spacing: 1px;
}

.language-dropwdown-button-black{
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: #111111;
    font-size: 18px;
    letter-spacing: 1px;
}