.InspirationsDesignWrapper{
    margin: 150px 0;

    h6{
        color: #B12634;
        font-size: 15px;
    }

    .InspirationsDesignItems{
        ::-webkit-scrollbar {
            height: 7px;
        }

        ::-webkit-scrollbar-track {
            background-color: #E0DED2; 
            border-radius: 10px;
        }
        
        ::-webkit-scrollbar-thumb {
            background: #B12634; 
            border-radius: 10px;
        }
        
        ::-webkit-scrollbar-thumb:hover {
            background: #B12634; 
        }
        
        margin: 50px 0;

        .InspirationsDesignItem{
            margin-bottom: 40px;

            h3{
                color: #1F1E1E;
                font-size: 17px;
                font-weight: 400;
                margin: 20px 0;
                padding: 0 10px;
            }

            .InspirationsDesignItemDescription{
                height: 120px;
                padding: 0 10px;
                color: rgba(29, 29, 31, 0.65);
                font-family: 'Poppins', sans-serif;
                font-size: 13px;
            }

            .ProductImage{
                border-radius: 10px;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .InspirationsDesignWrapper{
        margin: 50px 0;
    }
}