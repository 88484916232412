.ContactSectionWrapperItem{
    background-color: #F4EDE6;
    height: 100%;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    justify-content: center;
    padding: 0 100px;

    h4{
        color: #000000;
        font-weight: 400;
        font-size: 40px;
        margin-bottom: 30px;
    }

    p{
        font-family: 'Poppins', sans-serif;
        font-size: 17px;
        color: #1F1E1E;
        margin: 30px 0;
    }

    .ContactButton{
        background-color: #B12634;
        color: #ffffff;
        border: none;
        padding: 10px 30px;
        border-radius: 5px;
        font-size: 15px;
        text-transform: uppercase;
    }
}

.ContactImage{
    width: 100%;
}

@media only screen and (max-width: 900px) {
    .ContactSectionWrapperItem{
        padding: 20px;
    }
}