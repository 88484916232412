.OurProjectWrapper{
    margin: 150px 0;

    h6{
        color: #B12634;
        font-size: 15px;
    }

    .OurProjectItems{
        ::-webkit-scrollbar {
            height: 7px;
        }

        ::-webkit-scrollbar-track {
            background-color: #E0DED2; 
            border-radius: 10px;
        }
        
        ::-webkit-scrollbar-thumb {
            background: #B12634; 
            border-radius: 10px;
        }
        
        ::-webkit-scrollbar-thumb:hover {
            background: #B12634; 
        }
        
        margin: 50px 0;
        
        .OurProjectsItem{
            margin-bottom: 40px;

            h3{
                color: #1F1E1E;
                font-size: 17px;
                font-weight: 100;
                margin: 20px 0;
                padding: 0 10px;
            }

            button{
                background-color: #fff;
                color: #B12634;
                padding: 0 10px;
                font-weight: 500;
                text-transform: uppercase;
                font-size: 14px;
                border: none;
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .OurProjectWrapper{
        margin: 50px 0;
    }
}