.NavbarWrapper{
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    padding: 20px 0;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;

    a{
        color: #EE3638;
        font-weight: 600;
        text-decoration: none;
        margin-right: 20px;
        text-transform: uppercase;
        font-size: 13px;
    }

    a:hover{
        color: #EE3638;
    }

    img{
        margin-right: 100px;
    }
}