.ProductsWrapper{
    margin: 50px 0;
    .Title{
        color: #B12634;
        font-size: 32px;
        text-align: center;
    }

    .SubTitle{
        color: #000000;
        font-size: 35px;
        font-weight: 600;
        text-align: center;
    }

    .CategoryProductsWrapper{
        margin: 50px 0;

        .CategoryItem{
            margin-bottom: 100px;

            .CategoryName{
                color: #000000;
                font-weight: 400;
                text-transform: uppercase;
                font-size: 30px;
                border-bottom: 1px solid #B12634;
                padding-bottom: 10px;
                width: 300px;
            }

            .ProductImage{
                width: 100%;
                border-radius: 10px;
            }

            .MoreButton{
                margin-top: 15px;
                color: #B12634;
                cursor: pointer;
            }
        }
    }
}

.AboutUsWrapper{

    .small{
        display: none;
    }

    .AboutUsNav{
        background-color: #B12634;

        .Nav{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            align-items: center;
            padding: 15px 0;

            img{
                width: 130px;
            }

            a{
                margin-right: 30px;
                color: #fff;
                text-decoration: none;
            }
        }
    }

    .AboutUsItem{
        margin-bottom: 100px;

        .AboutUsItemTitle{
            font-size: 35px;
            border-bottom: 1px solid #B12634;
            padding-bottom: 20px;
            width: 300px;
            margin-bottom: 30px;
            font-weight: 600;
        }
    }

    @media only screen and (max-width: 900px) {
        .small{
            display: block;
        }

        .medium{
            display: none;
        }

        .AboutUsNav{

            .Nav{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

.AllProductsWrapper{
    margin: 50px 0;
    .Title{
        color: #B12634;
        font-size: 32px;
        text-align: center;
    }

    .SubTitle{
        color: #000000;
        font-size: 35px;
        font-weight: 600;
        text-align: center;
    }
    
    .AllProductImage{
        width: 100%;
    }
}