.CustomersWrapper{
    margin: 150px 0;

    h6{
        color: #B12634;
        font-size: 15px;
    }

    .CustomerItemWrapper{
        border: 1px solid #CACACA;
        border-radius: 8px;
        padding: 50px 100px;
        margin-top: 30px;

        .Main{
            display: flex;
            margin-bottom: 20px;

            .Numbers{
                display: flex;

                .Underline{
                    height: 1px;
                    background: #CACACA;
                    width: 100px;
                    margin-top: 8px;
                    margin-left: 10px;
                    margin-right: 10px;
                }

                p{
                    color: #1D1D1F !important;
                    font-weight: 100;
                }

                .Active{
                    color: #1F1E1E !important;
                }
            }

            .Arrows{
                display: flex;
                margin-left: 50px;
                user-select: none;

                p{
                    cursor: pointer;
                    font-weight: bold;
                    font-size: 30px;
                    margin-top: -15px;
                    color: #B12634;
                }

                .RightArrow{
                    margin-left: 15px;
                }
            }
        }

        .Rate{
            span{
                font-weight: bold;
            }
            img{
                margin-top: -5px;
            }
        }

        .Description{
            h4{
                font-weight: 600;
                font-size: 30px;
            }
            p{
                margin: 20px 0;
                padding: 0 10px;
            }
            h5{
                font-weight: bold;
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .CustomersWrapper{
        margin: 50px 0;

        .CustomerItemWrapper{
            padding: 20px;
        }
    }
}