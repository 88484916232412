.CategoriesWrapper{
    p{
        margin: 0;
    }

    .PageTitle{
        font-weight: 600;
    }

    .CategoriesListItemsWrapper{
        padding: 20px;

        .CategoryItem{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            align-items: center;
            margin-bottom: 20px;

            img {
                width: 100px;
                margin-right: 20px;
            }

            .CategoryName{
                font-weight: 600;
                font-size: 14px;
            }
        }

        .CategoryItem:hover{
            background-color: rgba(235, 183, 183, 0.281);
            border-radius: 3px;
        }
    }
}

.NewCategoryWrapper{
    display: grid;
    gap: 12px;
}