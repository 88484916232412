.AboutUsSectionImage{
    width: 100%;
}

.AboutWrapper{
    background-color: #F4EDE6;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 80px;

    h6{
        color: #B12634;
        font-size: 15px;
    }
    h4{
        font-size: 26px;
        margin-top: 20px;
    }
    p{
        font-size: 12px;
        margin: 40px 0;
    }

    span{
        font-size: 12px;
    }

    .ContactNowButton{
        margin-top: 40px;
        background-color: #B12634;
        color: #ffffff;
        border: none;
        padding: 10px 30px;
        border-radius: 5px;
        font-size: 15px;
    }
}

@media only screen and (max-width: 900px) {
    .AboutWrapper{
        padding: 20px;
    }
}