.FooterWrapper{
    border-top: 1px solid #212529;
    padding: 20px;

    .Footer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;

        p{
            margin: 0 !important;
        }

        .LogoFooter{
            width: 60%;
        }

        .ContactFormWrapper{
            background-color: #F4EDE6;
            padding: 20px;
            border-radius: 10px;

            h4{
                text-align: center;
            }

            .ContactForm{
                display: grid;
                gap: 10px;
            }
        }
    }
    @media only screen and (max-width: 900px) {
        .Footer{
            .LogoFooter{
                width: 200px;
                margin: 0 auto;
            }
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 30px;

            .PhoneInput{
                margin-top: 10px;
            }
        }
    }
}