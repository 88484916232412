.OurMissionWrapper{
    margin: 150px 0;

    h6{
        color: #B12634;
        font-size: 15px;
    }

    .Images{
        margin: 30px 0;

        img {
            margin: 0 20px;
        }
    }
}

@media only screen and (max-width: 900px) {
    .OurMissionWrapper{
        margin: 20px 0;

        .Images{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 10px;

            img{
                margin: 0 auto;
            }
        }
    }
}