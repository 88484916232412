.LoginWrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .FormWrapper{
        width: 400px;
        display: grid;
        grid-template-rows: auto;
        gap: 30px;
        text-align: center;
    }
}