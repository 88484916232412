.MessagesWrapper{
    .MessagesListWrapper{
        .MessageItem{
            border-top: 1px solid #111;
            border-bottom: 1px solid #111;
            padding: 5px 20px;
            margin: 10px 0;

            p{
                font-size: 13px;
            }
        }
    }
}