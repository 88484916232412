.InfoSectionWrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .InfoSectionItemWrapper{
        margin: 100px 20px;
        display: flex;

        h3{
            margin-right: 10px;
            margin-top: 10px;
            font-size: 18px;
        }

        h4{
            font-size: 18px;
        }

        p{
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 900px) {
    .InfoSectionWrapper{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin: 20px 0;

        .InfoSectionItemWrapper{
            margin: 20px 0;
        }
    }
}