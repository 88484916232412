.ProductsWrapper{
    p{
        margin: 0;
    }

    .PageTitle{
        font-weight: 600;
    }

    .TableRowHover:hover{
        background-color: rgba(235, 183, 183, 0.281);
        border-radius: 3px;
    }
}

.NewProductWrapper{
    display: grid;
    gap: 12px;
}