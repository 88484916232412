body {
  margin: 0;
  font-family: 'Benne' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.on-hover{
  cursor: pointer;
}

.icon-success{
  color: #19CF68;
}
.icon-danger{
  color: #F13F42;
}